import isString from 'lodash/isString';

export default class FormErrors {
    /**
     * FormErrors constructor.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if field has error.
     * @param {String} name
     * @returns {Boolean}
     */
    has(name) {
        return this.errors.errors && this.errors.errors[name];
    }

    /**
     * Get field errors.
     * @param {String} name
     * @returns {Array|null}
     */
    get(name) {
        if (!this.errors || !this.errors.errors) {
            return null;
        }

        return this.errors.errors[name];
    }

    /**
     * Count how many errors exist using regexp.
     * @param {RegExp} regexp
     * @returns {Number}
     */
    errorsCountExp(regexp) {
        if (!this.errors || !this.errors.errors) {
            return 0;
        }

        const expression = isString(regexp) ? new RegExp(regexp) : regexp;
        return Object.keys(this.errors.errors)
            .filter(field => field.match(expression) !== null).length;
    }

    /**
     * Set errors.
     * @param {*} errors
     */
    setErrors(errors) {
        this.errors = errors;
    }

    /**
     * Clear errors.
     */
    clear() {
        this.errors = {};
    }

    /**
     * Update errors from response.
     * @param {AxiosResponse} response
     * @returns {*}
     */
    updateFromResponse(response) {
        if (isString(response) || !response.response) {
            return response;
        }

        if (typeof response.response.data === 'object' && typeof response.response.data.error === 'object') {
            this.setErrors(response.response.data.error);
        }

        return response;
    }
}
