<script>
import { FormModel } from '@/components/global/form';
import _pickBy from 'lodash/pickBy';
import { scrollToError, splitArrayIntoChunks } from './helpers';

export default {
    name: 'CcPlansInfoFrom',

    data() {
        return {
            loading: false,
            formSent: false,
            model: new FormModel({
                receive_salary: false,
                receive_benefit: false,
                receive_scholarship: false,
                receive_pension: false,
                is_receive_other: false,
                receive_other: null,

                send_taxes: false,
                send_salary: false,
                send_utilities: false,
                is_send_other: false,
                send_other: null,

                other_purpose_of_opening: null,

                is_other_credit_union_feature: false,
                other_credit_union_feature: null,

                credit_union_features: [],
                average_monthly_turnover_id: null,
                monthly_transactions_count_id: null,
                monthly_cash_flow_amount_id: null,
                monthly_cash_transactions_count_id: null,

                receive_money_from: null,
                send_money_to: null,
                updatable: null,
            }),
            meta: {
                credit_union_features: [],
                average_monthly_turnovers: [],
                monthly_transactions_counts: [],
                monthly_cash_flow_amounts: [],
                monthly_cash_transactions_counts: [],
            },
        };
    },

    computed: {
        creditUnionFeatureColumns() {
            return splitArrayIntoChunks(this.meta.credit_union_features, 3);
        },

        averageMonthlyTurnoverColumns() {
            return splitArrayIntoChunks(this.meta.average_monthly_turnovers, 3);
        },

        monthlyTransactionsCountColumns() {
            return splitArrayIntoChunks(this.meta.monthly_transactions_counts, 4);
        },

        monthlyCashFlowAmountColumns() {
            return splitArrayIntoChunks(this.meta.monthly_cash_flow_amounts, 3);
        },

        monthlyCashTransactionsCountColumns() {
            return splitArrayIntoChunks(this.meta.monthly_cash_transactions_counts, 4);
        },
    },

    created() {
        this.load();
        this.$root.$on('next-step', this.submit);
    },

    methods: {
        load() {
            this.$http.get('/api/credit-union-features/source').then(response => {
                this.meta.credit_union_features = response.data.data || [];
            });

            this.$http.get('/api/average-monthly-turnovers/source').then(response => {
                this.meta.average_monthly_turnovers = response.data.data || [];
            });

            this.$http.get('/api/monthly-transactions-counts/source').then(response => {
                this.meta.monthly_transactions_counts = response.data.data || [];
            });

            this.$http.get('/api/monthly-cash-flow-amounts/source').then(response => {
                this.meta.monthly_cash_flow_amounts = response.data.data || [];
            });

            this.$http.get('/api/monthly-cash-transactions-counts/source').then(response => {
                this.meta.monthly_cash_transactions_counts = response.data.data || [];
            });

            this.$http.get('/api/membership-request').then(response => {
                const data = _pickBy(response.data.data, item => item !== null);
                this.model.$ = { ...this.model.$, ...data };
            });
        },

        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/registracija/informacija-apie-planus', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },
    },
};
</script>
