import * as Sentry from '@sentry/vue';

export default class SentryReporting {
    constructor() {
        this.logHttpResponses = true;
    }

    report(error) {
        // Ignore HTTP errors
        if (error.response) {
            if (this.logHttpResponses) {
                if (error.response.status >= 500) {
                    console.error(error);
                } else if (error.response.status >= 400) {
                    console.warn(error);
                }
            }
            return;
        }

        // Ignore Network Errors. This includes no internet etc.
        if (!error.response && (error.code === 'ECONNABORTED' || error.message === 'Network Error' || error.message === 'timeout of 0ms exceeded')) {
            return;
        }

        // Ignore chrome + grammarly error
        if (error.message === 'ResizeObserver loop limit exceeded') {
            return;
        }
        if (error.message === 'Request aborted') {
            return;
        }
        // Ignore router errors
        if (error._isRouter) {
            console.error(error._isRouter);
            return;
        }
        if (error instanceof Error) {
            this.reportException(error);
        }
        console.error(error);
    }

    reportException(exception) {
        Sentry.captureException(exception);
    }
}
