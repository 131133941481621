<template>
    <button @click.prevent="onClick" class="cc-btn" :class="{'cc-btn--loading': loading}">
        <span class="cc-btn__content">
            <slot></slot>
        </span>
        <span class="cc-btn__spinner">
            <span class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </span>
        </span>
    </button>
</template>
<script>
    export default {
        name: 'CcButton',

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            onClick(e) {
                this.$emit('click', e);
            },
        },
    };
</script>
