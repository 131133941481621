<template>
    <div class="form-group" :class="{'has-error': hasError}">
        <!-- label -->
        <label v-if="showLabel" class="control-label" :class="labelClasses">
            <span v-if="required" class="text-danger">*</span>
            <slot name="label">{{ label || '&nbsp;' }}</slot>
        </label>

        <!-- inputs -->
        <div v-if="layoutType === 'horizontal'" :class="inputClasses">
            <slot></slot>
            <v-form-error :error="error"></v-form-error>
        </div>
        <template v-else>
            <slot></slot>
            <v-form-error :error="error"></v-form-error>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'form-group',

        inject: {
            form: {
                default: null,
            },
        },

        props: {
            label: {
                type: String,
            },
            errors: null,
            error: {
                type: String,
            },
            labelClass: {
                type: String,
                default: 'col-sm-3 col-lg-2',
            },
            inputClass: {
                type: String,
                default: 'col-sm-9 col-lg-10',
            },
            forcedInputClass: {
                type: String,
                default: '',
            },
            layout: {
                type: String,
                default: 'inherit',
                validator(value) {
                    return ['inherit', 'horizontal', 'vertical'].indexOf(value) >= 0;
                },
            },
            required: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            showLabel() {
                return typeof this.label === 'string' || this.$slots.label;
            },

            layoutType() {
                if (this.layout !== 'inherit') {
                    return this.layout;
                }

                if (this.form) {
                    return this.form.layout;
                }

                return 'horizontal';
            },

            labelClasses() {
                const list = [];

                if (this.layoutType === 'horizontal') {
                    list.push('text-right');
                    list.push(this.labelClass);
                }

                return list;
            },

            inputClasses() {
                const list = [];

                if (this.layoutType === 'horizontal' && this.showLabel) {
                    list.push(this.inputClass);
                }

                list.push(this.forcedInputClass);

                return list;
            },

            errorsSource() {
                if (this.errors) {
                    return this.errors;
                }

                if (this.form && this.form.errors) {
                    return this.form.errors;
                }

                return null;
            },

            hasError() {
                if (!this.error || !this.errorsSource) {
                    return false;
                }

                if (this.errorsSource.has(this.error)) {
                    return true;
                }

                return false;
            },
        },
    };
</script>
