<script>
import { FormModel } from '@/components/global/form';
import _pickBy from 'lodash/pickBy';
import { scrollToError } from './helpers';

const countryOfResidenceTemplate = {
    title: null,
    tax_identification_number: null,
    no_tax_identification_number: false,
};

export default {
    name: 'CcClientInformationFrom',

    data() {
        return {
            loading: false,
            formSent: false,
            model: new FormModel({
                name: null,
                last_name: null,
                personal_code: null,
                address: null,
                phone: null,
                additional_contact: null,
                is_resident_of_lithuania: true,
                updatable: null,
                countries_of_residence: [
                    { ...countryOfResidenceTemplate },
                ],
                residence_confirmation: null,
            }),
            meta: {
                credit_unions: [],
            },
        };
    },

    created() {
        this.load();
        this.$root.$on('next-step', this.submit);
    },

    computed: {
        creditUnion() {
            return this.meta.credit_unions.find(item => item.id === this.model.$.credit_union_id);
        },
    },

    methods: {
        load() {
            this.$http.get('/api/credit-unions/source').then(response => {
                this.meta.credit_unions = response.data.data || [];
            });

            this.$http.get('/api/membership-request').then(response => {
                const data = _pickBy(response.data.data, item => item !== null);
                this.model.$ = { ...this.model.$, ...data };
                if (!this.model.$.countries_of_residence.length) {
                    this.model.$.countries_of_residence = [{ ...countryOfResidenceTemplate }];
                }
            });
        },

        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/registracija/informacija-apie-klienta', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },

        addCountryOfResidence() {
            this.model.$.countries_of_residence.push({ ...countryOfResidenceTemplate });
        },

        removeCountryOfResidence(index) {
            this.model.$.countries_of_residence.splice(index, 1);
        },

        noTaxIdentificationToggle(item) {
            item.no_tax_identification_number = !item.no_tax_identification_number;
            if (item.no_tax_identification_number) {
                item.tax_identification_number = null;
            }
        },
    },
};
</script>
