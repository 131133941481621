(function () {
    function setCookie(cvalue) {
        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toGMTString()}`;
        document.cookie = `cookieconsent=${JSON.stringify(cvalue)};${expires};path=/`;
    }

    function getCookie(cname) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    function closeCookieAgreement() {
        const cookiesAgreementBar = document.getElementById('cookie-agreement');
        cookiesAgreementBar.classList.remove('cookie-agreement-open');
        cookiesAgreementBar.classList.add('cookie-agreement-close');
        setTimeout(() => {
            cookiesAgreementBar.style.display = 'none';
        }, 350);
    }

    function createCookiesAgreement() {
        if (!getCookie('cookieconsent')) {
            const cookiesAgreementBar = document.getElementById('cookie-agreement');
            const agreeBtn = document.getElementById('cookies-agree');

            cookiesAgreementBar.classList.remove('cookie-agreement-close');
            cookiesAgreementBar.style.display = 'block';
            cookiesAgreementBar.classList.add('cookie-agreement-open');

            agreeBtn.addEventListener('click', () => {
                const data = {
                    functional_cookies: true,
                };
                setCookie(data);
                closeCookieAgreement();
            });
        }
    }

    if (document.getElementById('cookie-agreement').classList.contains('cookie-agreement-open')) {
        closeCookieAgreement();
    } else {
        createCookiesAgreement();
    }

    (function () {
        const cookieAgreements = getCookie('cookieconsent');
        if (cookieAgreements === '') {
            createCookiesAgreement();
        }
    })();
})();
