/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

require('./Components/cookies');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.interceptors.response.use(response => {
    if (response.data.data && response.data.data.redirect) {
        window.location = response.data.data.redirect;
    }
    return response;
});

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

import { Form, FormError, FormGroup } from '@/components/global/form';
import SentryReporting from '@/services/SentryReporting';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import IdentityConfirmationForm from './Components/IdentityConfirmationForm';
import Vue from 'vue';
import Vuex from 'vuex';
import Button from './Components/Button';
import ClientInfoForm from './Components/ClientInfoForm';
import ConfirmationForm from './Components/ConfirmationForm';
import JobInfoForm from './Components/JobInfoForm';
import MembershipFeeForm from './Components/MembershipFeeForm';
import PlansInfoForm from './Components/PlansInfoForm';
import PoliticalVulnerabilityInfoForm from './Components/PoliticalVulnerabilityInfoForm';
import RegistrationForm from './Components/RegistrationForm';
import Tippy from './Components/Tippy';


Vue.prototype.$http = window.axios;
Vue.prototype.$sentyReporting = new SentryReporting();
Vue.component('cc-registration-form', RegistrationForm);
Vue.component('cc-identity-confirmation-form', IdentityConfirmationForm);
Vue.component('cc-client-info-form', ClientInfoForm);
Vue.component('cc-job-info-form', JobInfoForm);
Vue.component('cc-plans-info-form', PlansInfoForm);
Vue.component('cc-political-vulnerability-info-form', PoliticalVulnerabilityInfoForm);
Vue.component('cc-confirmation-form', ConfirmationForm);
Vue.component('cc-membership-fee-form', MembershipFeeForm);
Vue.component('v-form', Form);
Vue.component('v-form-group', FormGroup);
Vue.component('v-form-error', FormError);
Vue.component('v-button', Button);
Vue.component('cc-tippy', Tippy);

let sentryDsn = document.head.querySelector('meta[name="sentry-vue-dsn"]');
if (sentryDsn) {
    sentryDsn = sentryDsn.content;

    let sentryRelease = document.head.querySelector('meta[name="sentry-vue-release"]');
    sentryRelease = sentryRelease ? sentryRelease.content : '1.0.0';
    Sentry.init({
        Vue,
        release: sentryRelease,
        dsn: sentryDsn,
        integrations: [new Integrations.BrowserTracing()],
    });
}

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        is_able_to_go_back: true,
    },
    mutations: {
        toggleGoBack(state) {
            state.is_able_to_go_back = !state.is_able_to_go_back;
        },
    },
});

// eslint-disable-next-line no-new
new Vue({
    el: '#app',
    store,
});
