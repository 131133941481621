import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { cloneData } from 'utils/Data';
import FormErrors from './FormErrors';

export default class FormModel {
    /**
     * FormModel constructor.
     * @param {*} modelBase
     */
    constructor(modelBase) {
        this.base = cloneDeep(modelBase);
        this.original = cloneDeep(modelBase);
        this.$ = cloneDeep(modelBase);
        this.$e = new FormErrors();
        this._dataMorph = false;
    }

    /**
     * Get model data, transformed if transformations applied.
     * Warning: do not bind fields to this getter - it's expensive.
     * @returns {*}
     */
    get data() {
        if (!this._dataMorph) {
            return this.$;
        }

        return cloneData(this.$, this._dataMorph);
    }

    /**
     * Set data transformation.
     * @param {false,*} transform
     * @returns {FormModel}
     */
    dataMorph(transform) {
        this._dataMorph = transform;

        return this;
    }

    /**
     * Set pristine model state.
     * @param {*} data
     */
    setPristine(data) {
        this.$ = Object.assign(cloneDeep(this.base), data);
        this.$e.setErrors({});
        this.original = Object.assign(cloneDeep(this.base), this.$);
    }

    /**
     * Reset model to base data.
     */
    clear() {
        this.$ = cloneDeep(this.base);
    }

    /**
     * Determine if the model is dirty.
     * @returns {boolean}
     */
    isDirty() {
        return !isEqual(this.$, this.original);
    }

    /**
     * Reset model to base values.
     */
    reset() {
        this.setPristine(this.base);
    }
}
