<script>
import { FormModel } from '@/components/global/form';
import _pickBy from 'lodash/pickBy';
import { scrollToError } from './helpers';

const POLITICALLY_VULNERABLE_RELATION = { type: null, full_name: null, info: null };
export default {
    name: 'CcPoliticalVulnerabilityInfoForm',

    data() {
        return {
            loading: false,
            formSent: false,
            model: new FormModel({
                is_politically_vulnerable: null,
                political_vulnerabilities: [],
                politically_vulnerable_relations: [
                    POLITICALLY_VULNERABLE_RELATION,
                ],
                is_owner_of_account: null,
                updatable: null,
            }),
            meta: {
                political_vulnerabilities: [],
                politically_vulnerable_relation_types: [
                    { id: 1, title: 'Esu pats' },
                    { id: 2, title: 'Aš esu šio asmens artimas šeimos narys' },
                    { id: 3, title: 'Aš esu šio asmens artimas pagalbininkas' },
                ],
            },
        };
    },

    created() {
        this.load();
        this.$root.$on('next-step', this.submit);
    },

    computed: {
        isSelfPoliticallyVulnerable() {
            return this.model.$.politically_vulnerable_relations.find(item => item.type === 1);
        },
    },

    methods: {
        load() {
            this.$http.get('/api/political-vulnerabilities/source').then(response => {
                this.meta.political_vulnerabilities = response.data.data || [];
            });

            this.$http.get('/api/membership-request').then(response => {
                const data = _pickBy(response.data.data, item => item !== null);
                this.model.$ = { ...this.model.$, ...data };
                if (!this.model.$.politically_vulnerable_relations.length) {
                    this.addRelation();
                }
            });
        },

        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/registracija/informacija-apie-politini-pazeidziamuma', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },

        addRelation() {
            this.model.$.politically_vulnerable_relations.push({ ...POLITICALLY_VULNERABLE_RELATION });
        },

        removeRelation(index) {
            this.model.$.politically_vulnerable_relations.splice(index, 1);
        },
    },
};
</script>
