export function splitArrayIntoChunks(arr, count = 1) {
    const chunks = [];
    let i = 0;
    const n = arr.length;
    const itemCount = Math.ceil(n / count);
    while (i < n) {
        chunks.push(arr.slice(i, i += itemCount));
    }
    return chunks;
}

export function scrollToError() {
    setTimeout(() => {
        const elements = document.getElementsByClassName('has-error');
        if (elements.length) {
            elements[0].scrollIntoView();
        }
    }, 300);
}
