<template>
    <form @submit.prevent="$emit('submit')" :class="{ 'form-horizontal': layout === 'horizontal' }">
        <slot></slot>
    </form>
</template>

<script>
    export default {
        name: 'form-wrapper',

        props: {
            layout: {
                type: String,
                default: 'horizontal',
                validator(value) {
                    return ['horizontal', 'vertical'].indexOf(value) >= 0;
                },
            },

            errors: null,
        },

        provide() {
            return {
                form: this,
            };
        },
    };
</script>
