<script>
import { FormModel } from '@/components/global/form';
import _pickBy from 'lodash/pickBy';
import { scrollToError, splitArrayIntoChunks } from './helpers';

const JOB_TYPE_HIRED_WORKER = 1;
const JOB_TYPE_INDIVIDUAL_ACTIVITY = 2;
const JOB_TYPE_OTHER_ACTIVITY = 7;

export default {
    name: 'CcJobInfoFrom',

    data() {
        return {
            loading: false,
            formSent: false,
            model: new FormModel({
                job_type_id: null,
                workplace: null,
                job_title: null,
                individual_activities: [],
                is_other_individual_activity: false,
                other_individual_activity: null,
                is_other_activity: false,
                other_activity: null,
                updatable: null,

                income_sources: [],
                is_other_income_source: false,
                other_income_source: null,

                average_monthly_income_id: null,
            }),
            meta: {
                individual_activities: [],
                income_sources: [],
                average_monthly_incomes: [],
                job_types: [],
            },
        };
    },

    created() {
        this.load();
        this.$root.$on('next-step', this.submit);
    },

    computed: {
        creditUnion() {
            return this.meta.credit_unions.find(item => item.id === this.model.$.credit_union_id);
        },

        activitiesColumns() {
            return splitArrayIntoChunks(this.meta.individual_activities, 2);
        },

        incomeSourcesFirstHalf() {
            const length = Math.ceil(this.meta.income_sources.length / 2);
            let list = [...this.meta.income_sources];
            list = list.splice(0, length);

            return list;
        },

        incomeSourcesSecondHalf() {
            let list = [...this.meta.income_sources];
            list = list.splice(-this.incomeSourcesFirstHalf.length);

            return list;
        },
    },

    watch: {
        'model.$.is_other_income_source'() {
            if (this.model.$.is_other_income_source) {
                this.model.$.income_source_id = null;
            }
        },

        'model.$.income_source_id'() {
            if (this.model.$.income_source_id) {
                this.model.$.is_other_income_source = false;
            }
        },
    },

    methods: {
        load() {
            this.$http.get('/api/individual-activities/source').then(response => {
                this.meta.individual_activities = response.data.data || [];
            });

            this.$http.get('/api/income-sources/source').then(response => {
                this.meta.income_sources = response.data.data || [];
            });

            this.$http.get('/api/job-types/source').then(response => {
                this.meta.job_types = response.data.data || [];
            });

            this.$http.get('/api/average-monthly-incomes/source').then(response => {
                this.meta.average_monthly_incomes = response.data.data || [];
            });

            this.$http.get('/api/membership-request').then(response => {
                const data = _pickBy(response.data.data, item => item !== null);
                this.model.$ = { ...this.model.$, ...data };
            });
        },

        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/registracija/informacija-apie-veikla', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },

        isHiredWorker(itemId) {
            if (!itemId) {
                return false;
            }
            return this.model.$.job_type_id === JOB_TYPE_HIRED_WORKER && itemId === JOB_TYPE_HIRED_WORKER;
        },

        isIndividualActivity(itemId) {
            if (!itemId) {
                return false;
            }
            return this.model.$.job_type_id === JOB_TYPE_INDIVIDUAL_ACTIVITY && itemId === JOB_TYPE_INDIVIDUAL_ACTIVITY;
        },

        isOtherActivity(itemId) {
            if (!itemId) {
                return false;
            }
            return this.model.$.job_type_id === JOB_TYPE_OTHER_ACTIVITY && itemId === JOB_TYPE_OTHER_ACTIVITY;
        },
    },
};
</script>
