<template>
    <div v-if="hasError" class="validation-error-label">
        <div v-for="error in errorMessages" v-html="error"></div>
    </div>
</template>

<script>
    export default {
        name: 'form-error',

        inject: {
            form: {
                default: null,
            },
        },

        props: {
            errors: null,
            error: {
                type: String,
            },
        },

        computed: {
            errorsSource() {
                if (this.errors) {
                    return this.errors;
                }

                if (this.form && this.form.errors) {
                    return this.form.errors;
                }

                return null;
            },

            hasError() {
                if (!this.error || !this.errorsSource) {
                    return false;
                }

                if (this.errorsSource.has(this.error)) {
                    return true;
                }

                return false;
            },

            errorMessages() {
                if (!this.hasError) {
                    return '';
                }

                return this.errorsSource.get(this.error);
            },
        },
    };
</script>
