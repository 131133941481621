require('./bootstrap');

function ready(fn) {
    // eslint-disable-next-line no-negated-condition
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(() => {
    setTimeout(() => {
        const w = parseFloat(getComputedStyle(document.body, null).width.replace('px', ''));
        if (w < 991 && location.pathname.substr(1).length > 0) {
            location.href = '#app';
        }
    }, 300);
});
